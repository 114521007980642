<template>
  <TheNuxtLink
    :to="`/${locale}/news/article/${id}/${slug}`"
    class="w-full flex gap-4"
    :class="sidebar ? 'flex-col 2xl:flex-row' : 'flex-col'"
  >
    <img
      class="aspect-[16/9] object-cover hover-scale"
      :class="sidebar ? 'w-full 2xl:w-40' : 'w-full'"
      :src="cover"
    />
    <div class="space-y-2">
      <h2
        class="font-medium"
        :class="
          sidebar
            ? 'text-sm sm:text-base lg:text-sm xl:text-base line-clamp-2 2xl:line-clamp-3'
            : 'text-lg sm:text-xl line-clamp-2'
        "
        v-html="title"
      />
      <div
        v-if="excerpt"
        class="text-sm sm:text-base line-clamp-4"
        v-html="excerpt"
      />
    </div>
  </TheNuxtLink>
</template>

<script setup lang="ts">
interface Props {
  id: number;
  cover: string;
  title: string;
  excerpt?: string;
  sidebar?: boolean;
  slug: string;
}

defineProps<Props>();
const nuxtApp = useNuxtApp();

const locale = nuxtApp.$i18n.global.locale;
</script>
